.slider-wrapper {
    border-radius: 5px;
    width: auto;
    height: 2em;
    position: relative;
    cursor: pointer;
}

.slider-body {
    z-index: 2;
    position: absolute;
    width: 100%;
    height: 100%;
}

.slider-text {
    z-index: 1;
    user-select: none;
    text-align: left;
    vertical-align: middle;
}
