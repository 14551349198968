.model-wrapper {
    display: flex;
    flex-flow: row;
    flex-grow: 1;
    gap: 5px;
    overflow-y: clip;
}

.model-app span {
    color: var(--colour-text);
}

.model-app {
    display: flex;
    flex: 1;
    flex-flow: column nowrap;
    justify-content: center;
    position: relative;
    overflow: clip;
}

.model-controls {
    color: var(--colour-text);
    min-width: 300px;
    width: 300px;
    display: flex;
    flex-flow: column;
    border-right: solid 3px grey;
    padding-right: 6px;
    gap: 10px;
}

.model-status {
    min-width: 300px;
    width: 300px;
    display: flex;
    flex-flow: column;
    gap: 5px;
    border-left: solid 3px grey;
    padding-left: 6px;
    text-align: left;
    overflow-y: auto;
}

.model-status span {
    color: var(--colour-text);
}

.label-list {
    display: flex;
    flex-flow: column nowrap;
    gap: 5px;
}

.fast-forward-banner {
    z-index: 2;
    position: absolute;
    width: 100%;
    padding: 1em;
    top: 10%;
    color: var(--colour-text);
    background-color: var(--colour-primary);
    border-radius: 5px;
}

.dimmed {
    z-index: 1;
    filter: brightness(25%);
}

.topology-overlay {
    position: absolute;
    top: 0;
    right: 0;
}

.game-canvas {
    height: 100%;
}

.playback-controls {
    display: flex;
    flex-flow: row nowrap;
    gap: 5px;
}

.playback-control-button {
    flex: 1;
    font-family: 'Font Awesome 6 Free';
    font-weight: 900;
    display: inline-block;
}

@keyframes grow {
    from {
        opacity: 0;
        transform: translateY(50px);
    }
}

.staged-changes-banner {
    animation: grow 0.15s ease-in-out;
    display: flex;
    gap: 10px;
    flex-flow: row nowrap;
    z-index: 2;
    position: absolute;
    width: auto;
    padding: 1em;
    margin: 10px;
    left: 0;
    bottom: 0;
    color: var(--colour-text);
    background-color: var(--colour-primary);
    border-radius: 5px;
}
